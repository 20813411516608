define("discourse/plugins/discourse-presence/discourse/components/topic-presence-display", ["exports", "@ember/component", "@ember/object/computed", "@ember/service", "discourse-common/utils/decorators", "@ember/template-factory"], function (_exports, _component, _computed, _service, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.shouldDisplay}}
    <div class="presence-users">
      <div class="presence-avatars">
        {{#each this.users as |user|}}
          <UserLink @user={{user}}>
            {{avatar user imageSize="small"}}
          </UserLink>
        {{/each}}
      </div>
      <span class="presence-text">
        <span class="description">
          {{i18n "presence.replying_to_topic" count=this.users.length}}
        </span>
        <span class="wave">
          <span class="dot">.</span>
          <span class="dot">.</span>
          <span class="dot">.</span>
        </span>
      </span>
    </div>
  {{/if}}
  */
  {
    "id": "X0UPAmiw",
    "block": "[[[41,[30,0,[\"shouldDisplay\"]],[[[1,\"  \"],[10,0],[14,0,\"presence-users\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"presence-avatars\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"users\"]]],null]],null],null,[[[1,\"        \"],[8,[39,4],null,[[\"@user\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n          \"],[1,[28,[35,5],[[30,1]],[[\"imageSize\"],[\"small\"]]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n    \"],[10,1],[14,0,\"presence-text\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"description\"],[12],[1,\"\\n        \"],[1,[28,[35,7],[\"presence.replying_to_topic\"],[[\"count\"],[[30,0,[\"users\",\"length\"]]]]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,1],[14,0,\"wave\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"dot\"],[12],[1,\".\"],[13],[1,\"\\n        \"],[10,1],[14,0,\"dot\"],[12],[1,\".\"],[13],[1,\"\\n        \"],[10,1],[14,0,\"dot\"],[12],[1,\".\"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"user\"],false,[\"if\",\"div\",\"each\",\"-track-array\",\"user-link\",\"avatar\",\"span\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-presence/discourse/components/topic-presence-display.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend(dt7948.p({
    topic: null,
    presence: (0, _service.inject)(),
    replyChannel: null,
    whisperChannel: null,
    replyUsers(users) {
      return users?.filter(u => u.id !== this.currentUser.id);
    },
    whisperUsers(users) {
      return users?.filter(u => u.id !== this.currentUser.id);
    },
    users: (0, _computed.union)("replyUsers", "whisperUsers"),
    replyChannelName(id) {
      return `/discourse-presence/reply/${id}`;
    },
    whisperChannelName(id) {
      return `/discourse-presence/whisper/${id}`;
    },
    shouldDisplay: (0, _computed.gt)("users.length", 0),
    didReceiveAttrs() {
      this._super(...arguments);
      if (this.replyChannel?.name !== this.replyChannelName) {
        this.replyChannel?.unsubscribe();
        this.set("replyChannel", this.presence.getChannel(this.replyChannelName));
        this.replyChannel.subscribe();
      }
      if (this.currentUser.staff && this.whisperChannel?.name !== this.whisperChannelName) {
        this.whisperChannel?.unsubscribe();
        this.set("whisperChannel", this.presence.getChannel(this.whisperChannelName));
        this.whisperChannel.subscribe();
      }
    },
    _destroyed() {
      this.replyChannel?.unsubscribe();
      this.whisperChannel?.unsubscribe();
    }
  }, [["method", "replyUsers", [(0, _decorators.default)("replyChannel.users.[]")]], ["method", "whisperUsers", [(0, _decorators.default)("whisperChannel.users.[]")]], ["method", "replyChannelName", [(0, _decorators.default)("topic.id")]], ["method", "whisperChannelName", [(0, _decorators.default)("topic.id")]], ["method", "_destroyed", [(0, _decorators.on)("willDestroyElement")]]])));
});